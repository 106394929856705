import React, { Fragment, useState } from "react";

import { Link } from "gatsby";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BenefitSingle from "../components/benefitSingle";
import IngredientsHolder from "../components/ingredientsHolder";
import Follow from "../components/follow";
import VitaminMainCTA from "../components/vitaminMainCTA";
import VitaminIntro from "../components/vitaminIntro";
import { HalfButton2, Guarantees } from "../components/button";

const IngredientPage = ({ data }) => {
  const ingredient = data.contentfulIngredient;
  const [lang, setLang] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );
  const backToIng = data.allLabels.edges.find((el) => el.node.label === "back-to-ingredients")
  const capsules = data.allLabels.edges.find((el) => el.node.label === "ingredient-capsule")
  const dateDose = data.allLabels.edges.find((el) => el.node.label === "date-dose")
  const title1 = data.allLabels.edges.find((el) => el.node.label === "ingredient-title-1")
  const moreDetail = data.allLabels.edges.find((el) => el.node.label === "more-detail")
  const otherIng = data.allLabels.edges.find((el) => el.node.label === "see-the-other-ingredients")
  const thereAre = data.allLabels.edges.find((el) => el.node.label === "there-are")
  const studies1 = data.allLabels.edges.find((el) => el.node.label === "studies-text-1")
  const studies2 = data.allLabels.edges.find((el) => el.node.label === "studies-text-2")

  console.log("ms d", ingredient.benefitItems)
  return (
    <Layout>
      <SEO title="Home" />
      <div className="breadcrumb mobileOnly container">
        <Link to="/vitamin#allIngs">
          <img alt="chevron" src="/chevron.svg" />
          {backToIng && backToIng.node[lang]}
        </Link>
      </div>
      <div
        className="fullWidthPink ingredientCover mobileContainer"
        style={{
          backgroundImage: `url('${ingredient.image.file.url +
            "?w=3000&h=3000"}')`,
        }}
      >
        <div className="container">
          <div className="ingredientHeader">
            <div className="ingredientHeaderLeft">
              <div className="breadcrumb desktopOnly">
                <Link to="/vitamin#allIngs">
                  <img alt="chevron" src="/chevron.svg" />
                  {backToIng && backToIng.node[lang]}
                </Link>
              </div>
              <h1>{lang === "gr" ? ingredient.name.trim() : ingredient.nameInEnglish.trim()}</h1>
              <div className="rowTable rowTable-2cols">
                <div className="rowTableCell">{capsules && capsules.node[lang]}</div>
                <div className="rowTableCell bold">
                  {ingredient.dosage.trim()}
                </div>
                {ingredient.dailyIntake ? (
                  <>
                    <div className="rowTableCell">{dateDose && dateDose.node[lang]}</div>
                    <div className="rowTableCell bold">
                      {ingredient.dailyIntake.trim()}%
                    </div>
                  </>
                ) : null}
                {/* <div className="rowTableCell">Προέλευση:</div>
                <div className="rowTableCell bold">Παλέρμο, Ιταλία</div>
                <div className="rowTableCell">Παρασκευαστής:</div>
                <div className="rowTableCell bold">Βαφάρμ</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefitList">
        <h3>{title1 && title1.node[lang]}</h3>
        <ul>
          {ingredient.benefitItems.map((b, i) => (
            <li key={i}>{b.trim()} ✔︎</li>
          ))}
        </ul>
      </div>
      <div className="hr">&nbsp;</div>
      <h2 className="mainBenefit container">{lang === "gr" ? ingredient.benefitMain : ingredient.mainBenefitInEnglish}</h2>
      <div className="hr">&nbsp;</div>
      <div className="container">
        <div className="features ingredientFeature">
          <BenefitSingle
            isTextSideRight={true}
            feature={{
              title: moreDetail && moreDetail.node[lang],
              ctaText: otherIng && otherIng.node[lang],
              ctaLink: "/vitamin#allIngs",
              video: ingredient.video.file.url,
            }}
          >
            <p>{fixParagraph(lang === "gr" ? ingredient.benefitDetail.benefitDetail : ingredient.detailBenefitInEnglish.detailBenefitInEnglish)}</p>
          </BenefitSingle>
        </div>
      </div>
      <div className="hr desktopOnly">&nbsp;</div>
      <div className="citations container">
        <h2>
          {thereAre && thereAre.node[lang]}{" "}
          <span>{formatNumber(ingredient.numberOfStudies)} {lang === "gr" ? "έρευνες" : "studies"}</span> {lang === "gr" ? "για" : "for "}{" "}
          {lang === "gr" ? ingredient.name.trim() : ingredient.nameInEnglish.trim()}
        </h2>
        <p>
          {studies1 && studies1.node[lang]}
          <br />
          {studies2 && studies2.node[lang]}
        </p>
        <div className="container containerHalf">
          <div className="hr hrfull hrpeach">&nbsp;</div>
          {ingredient.studies.map((p, i) => (
            <ResearchPaper key={i} paper={p} lang={lang} data={data.allLabels.edges} />
          ))}
        </div>
      </div>
      <div className="hr desktopOnly">&nbsp;</div>
      <IngredientsHolder lang={lang} />
      <div className="hr mobileOnlySafe mobileOnly">&nbsp;</div>

      <VitaminIntro className="mobileOnlySafe mobileOnly" data={data.allLabels.edges} />

      <div className="fullWidthPink desktopOnly">
        <VitaminMainCTA data={data.allLabels.edges} />
      </div>
      <div className="mobileOnlySafe mobileOnly">
        <HalfButton2 price={lang === "gr" ? "€ 18.50/μήνα" : "€18.50/month"}
          lang={lang}
          data={data.allLabels.edges} />
        <Guarantees data={data.allLabels.edges} lang={lang} />
      </div>
      <Follow lang={lang} data={data.allLabels.edges} />
    </Layout>
  );
};

const fixParagraph = text =>
  text.split("\n").map((item, key) => {
    if (item) {
      return (
        <Fragment key={key}>
          {item}
          <br />
          <br />
        </Fragment>
      );
    } else {
      return false;
    }
  });

const formatNumber = num =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

const ResearchPaper = ({ paper, data, lang }) => {
  const readTheSurvey = data && data.find((el) => el.node.label === "read-the-survey")
  return (
    <>
      <div className="researchPaper">
        <div className="paperIcon">
          <img alt="research paper" src="/paper.svg" />
        </div>
        <div className="paperText">
          <span className="paperSource">{paper.journal.trim()}</span>
          <p className="paperTitle">
            {paper.title.title.trim()} - {paper.year}
          </p>
          <a
            className="paperLink"
            href={paper.link.trim()}
            target="_blank"
            rel="noreferrer noopener"
          >
            {readTheSurvey && readTheSurvey.node[lang]}
          </a>
        </div>
      </div>
      <div className="hr hrfull hrpeach">&nbsp;</div>
    </>
  );
};

export default IngredientPage;

export const query = graphql`
  query($id: String!) {
    contentfulIngredient(id: { eq: $id }) {
      benefitItems
      benefitDetail {
        benefitDetail
      }
      detailBenefitInEnglish{
        detailBenefitInEnglish
      }
      benefitMain
      mainBenefitInEnglish
      childContentfulIngredientBenefitDetailTextNode {
        benefitDetail
      }
      dailyIntake
      dosage
      name
      nameInEnglish
      numberOfStudies
      image {
        file {
          url
        }
      }
      video {
        file {
          url
        }
      }
      slug
      studies {
        journal
        link
        year
        title {
          title
        }
      }
    }
    allLabels {
      edges {
        node {
          label
          gr
          en
        }
      }
    }
  }
`;
